import React, { useState, useEffect, useRef } from "react";

interface ResizableIframeProps {
  srcDoc: string;
}

const ResizableIframe: React.FC<ResizableIframeProps> = ({ srcDoc }) => {
  const [height, setHeight] = useState<string>("0px");
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const resizeIframe = () => {
      if (iframeRef.current) {
        try {
          // Directly accessing the iframe document for same-origin content
          const iframe = iframeRef.current;
          const document =
            iframe.contentDocument || iframe.contentWindow?.document;
          if (document) {
            const body = document.body;
            const html = document.documentElement;

            // Calculate the height based on the content
            const newHeight = Math.max(
              body.scrollHeight,
              body.offsetHeight,
              html.clientHeight,
              html.scrollHeight,
              html.offsetHeight
            );

            // Update the state to adjust the iframe's height
            setHeight(`${newHeight}px`);
          }
        } catch (error) {
          console.error("Error resizing iframe: ", error);
        }
      }
    };

    // Adjust the iframe height after the content is loaded
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", resizeIframe);
    }

    // Initial resize
    resizeIframe();

    // Cleanup
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", resizeIframe);
      }
    };
  }, [srcDoc]); // Dependency on srcDoc to re-run when content changes

  return (
    <iframe
      ref={iframeRef}
      srcDoc={srcDoc}
      width="100%"
      height={height}
      style={{ overflow: "hidden", border: "none" }}
      title="Resizable Iframe"
      onLoad={() => {
        if (
          iframeRef.current &&
          iframeRef.current.contentWindow?.document.documentElement
        ) {
          setHeight(
            `${iframeRef.current.contentWindow.document.documentElement.scrollHeight}px`
          );
        }
      }}
    ></iframe>
  );
};

export default ResizableIframe;
