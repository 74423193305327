import { supabase } from "../supabase";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import stanfordphoto from "../stanfordphoto.jpg";
import { Event } from "../types";
import ResizableIframe from "../components/ResizableIFrame";

type Props = {};

const EventPage = (props: Props) => {
  const { eventId } = useParams();
  const [event, setEvent] = useState<Event | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const { data, error } = await supabase
        .from("event")
        .select("*")
        .filter("id", "eq", eventId)
        .maybeSingle();
      if (error) {
        console.error(error);
        setErrorMsg(error.message);
      } else if (data == null) {
        setErrorMsg("Error: No event found");
      } else {
        setEvent(data as Event);
        console.log(data);
      }
    };
    fetchEvent();
  }, [eventId]);

  if (errorMsg) {
    return <div>{errorMsg}</div>;
  }

  if (event === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="eventPage">
      <Box>
        <img
          src={stanfordphoto}
          alt="Stanford"
          style={{ maxWidth: "100%", height: "auto" }}
        />
        {event.rsvpLink ? (
          <Link to={event.rsvpLink}>
            <Button
              size="large"
              variant="contained"
              style={{
                color: "#466094",
                float: "right",
              }}
            >
              <Typography style={{ color: "white" }}>RSVP</Typography>
            </Button>
          </Link>
        ) : (
          <div></div>
        )}
      </Box>

      <div
        className="event-page-container"
        style={{
          padding: "2em",
        }}
      >
        <h1
          style={{
            fontSize: "1.7em",
            marginBottom: "0.6em",
            marginTop: "0em",
          }}
        >
          {event.eventName}
        </h1>
        <Typography>{event.venueText}</Typography>
        <Typography>
          {new Date(event.startTime || "").toLocaleString("en-US", {
            dateStyle: "full",
            timeStyle: "short",
            timeZone: "Europe/London",
          })}
          {event.endTime && (
            <Typography>
              {" "}
              to{" "}
              {new Date(event.endTime || "").toLocaleString("en-US", {
                timeStyle: "short",
                timeZone: "Europe/London",
              })}
            </Typography>
          )}
        </Typography>

        <h2
          style={{
            marginTop: "1em",
            marginBottom: "0.5em",
          }}
        >
          Food Served:
        </h2>
        <Typography>{event.foodTypeText || "unknown"}</Typography>
        <h2
          style={{
            marginTop: "1em",
            marginBottom: "0.5em",
          }}
        >
          Original Message
        </h2>
        <ResizableIframe srcDoc={event.emailHtml || ""} />
        {/* <iframe
          srcDoc={event.emailHtml || ""}
          title="original email"
          // frameborder="0"
        ></iframe> */}
      </div>
    </div>
  );
};
export default EventPage;
