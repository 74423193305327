import React from "react";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

type Props = {
  eventId: number;
  title: string;
  time: string;
  location: string;
  food: string;
  rsvp: string;
};

let rsvpPresent = false;

function CustomCard({ eventId, title, time, location, food, rsvp }: Props) {
  rsvpPresent = rsvp !== "" ? true : false;
  return (
    <CardContent style={{ backgroundColor: "white" }}>
      <CardActions style={{ display: "inline-block", float: "right" }}>
        <Link to={`/event/${eventId}`}>
          <Button size="large" variant="contained" style={{ color: "#466094" }}>
            <Typography style={{ color: "white" }}>DETAILS</Typography>
          </Button>
        </Link>
      </CardActions>
      <Typography
        variant="h5"
        sx={{
          display: "-webkit-box",
          overflow: "hidden",
          "-webkit-box-orient": "vertical",
          WebkitLineClamp: 2,
          textOverflow: "ellipsis",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Typography component="div" color="text.primary">
        {new Date(time).toLocaleString("en-US", {
          dateStyle: "short",
          timeStyle: "short",
          timeZone: "Europe/London",
        })}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {location}
      </Typography>
      <Typography>RSVP : {rsvpPresent ? "Yes" : "No"}</Typography>
      <Typography sx={{ mb: 1.5 }} color="text.primary">
        {food}
      </Typography>
    </CardContent>
  );
}

export default CustomCard;
