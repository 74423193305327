import "./App.css";
import "leaflet/dist/leaflet.css";
import HomePage from "./pages/HomePage";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import EventPage from "./pages/EventPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/event/:eventId",
    element: <EventPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
