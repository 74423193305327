import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Stack, Typography } from "@mui/material";

import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import Box from "@mui/material/Box";
import { useSwipeable } from "react-swipeable";
import CustomCard from "../components/CustomCard";
import { supabase } from "../supabase";
import { Event } from "../types";

type Props = {};

export const icon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/12585/12585469.png",
  iconSize: [35, 40],
});

export const bigIcon = new Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/128/2776/2776000.png",
  iconSize: [50, 55],
});

const HomePage = (props: Props) => {
  const [activeEvent, setActiveEvent] = useState<Event | null>(null);
  const [inputFood, setinputFood] = useState("");
  const [inputDate, setinputDate] = useState<Dayjs | null>(null);
  const [inputTime, setinputTime] = useState<Dayjs | null>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [inputError, setInputError] = useState<boolean>(false);

  const fetchSetAllEvents = async () => {
    const { data, error } = await supabase.from("event").select("*");
    if (error) {
      console.error(error);
    } else {
      setEvents(data as Event[]);
      console.log(data);
    }
  };

  useEffect(() => {
    fetchSetAllEvents();
  }, []);

  const handlers = useSwipeable({
    onSwipedDown: (eventData) => {
      // Handle swipe gestures here
      console.log("Swiped!", eventData);
      if (activeEvent) {
        setActiveEvent(null);
      }
    },
    trackMouse: true,
    trackTouch: true,
  });

  function MapClicker() {
    useMapEvents({
      click: () => {
        //map.locate();
        console.log("clicked map");
        if (activeEvent) {
          setActiveEvent(null);
        }
      },
      locationfound: (location) => {
        console.log("location found:", location);
      },
    });
    return null;
  }

  async function cancelClicked() {
    setinputFood("");
    setinputDate(null);
    setinputTime(null);
    fetchSetAllEvents();
  }

  async function searchClicked() {
    console.log("food entered is ", inputFood);
    console.log("time entered is ", inputTime);
    console.log("date entered is ", inputDate);

    if (inputTime && !inputDate) {
      setInputError(true);
    } else {
      if (inputError) setInputError(false);
    }

    const baseQuery = supabase
      .from("event")
      .select("*")
      .ilike("foodTypeText", `%${inputFood}%`);

    // Set the time filters depending on the logic. There are two possible states
    // 1: inputDate is set but inputTime is not set: then we want to show
    // all events with a start time on that date
    // 2: inputDate and inputTime are both set: then we want to show all events
    // that are happening at that time
    //3: inputTime is set but inputDate is not set should not work, hence user should get a warning: then we want to show all events with that start time for all dates.
    let combinedQuery = baseQuery;

    if (inputDate && !inputTime) {
      combinedQuery = baseQuery
        .lte("startTime", inputDate.format("YYYY-MM-DD 23:59:59"))
        .gte("startTime", inputDate.format("YYYY-MM-DD 00:00:00"));
    } else if (inputDate && inputTime) {
      console.log("date obj before, ", inputDate);
      const dateTime: Dayjs = inputDate
        .set("hour", inputTime.hour() || 0)
        .set("minutes", inputTime.minute());
      console.log("date obj after, ", dateTime);
      combinedQuery = baseQuery
        .lte("startTime", dateTime.format("YYYY-MM-DDTHH:mm:ss+00:00"))
        .gte("endTime", dateTime.format("YYYY-MM-DDTHH:mm:ss+00:00"));
    }

    const { data, error } = await combinedQuery;
    if (error) {
      console.error(error);
    } else {
      setEvents(data as Event[]);
      console.log(data);
    }
  }

  return (
    <div>
      <MapContainer
        center={[37.42760467529297, -122.16700744628906]}
        zoom={13}
        scrollWheelZoom={true}
        className="map-main"
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Box>
          <Stack
            direction="row"
            gap={1}
            style={{
              display: "flex",
              position: "absolute",
              width: "80%",
              top: "3%",
              left: "10%",
              zIndex: 10000,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {(inputFood !== "" || inputDate || inputTime) && (
                <IconButton sx={{ padding: 0 }} onClick={cancelClicked}>
                  <ClearIcon sx={{ backgroundColor: "white" }} />
                </IconButton>
              )}
              <DatePicker
                label="Date"
                sx={{ backgroundColor: "#ebedda", flex: "3", margin: "2" }}
                value={inputDate}
                onChange={setinputDate}
              />
              <MobileTimePicker
                label="Time"
                sx={{ backgroundColor: "#ebedda", flex: "3", margin: "2" }}
                value={inputTime}
                onChange={setinputTime}
              />
              <TextField
                sx={{ backgroundColor: "#ebedda", flex: "3", padding: "2" }}
                id="outlined-basic"
                label="Food"
                variant="outlined"
                value={inputFood}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setinputFood(event.target.value);
                }}
              />
              <IconButton sx={{ flex: "1" }} onClick={searchClicked}>
                <SearchIcon
                  sx={{ backgroundColor: "white", fontSize: "120%" }}
                />
              </IconButton>
            </LocalizationProvider>
          </Stack>
          {inputError && (
            <Typography
              color="error"
              variant="h6"
              style={{
                position: "absolute",
                top: "11%",
                left: "15%",
                zIndex: 10000,
                fontWeight: "bold",
              }}
            >
              Please select both date and time or just a date
            </Typography>
          )}
        </Box>
        {events.map((event) => (
          <Marker
            key={event.id}
            position={[event.latitude, event.longitude]}
            eventHandlers={{
              click: (e) => {
                setActiveEvent(event);
              },
            }}
            icon={activeEvent?.id === event.id ? bigIcon : icon}
          />
        ))}
        <MapClicker />
      </MapContainer>
      {activeEvent ? (
        <Box
          {...handlers}
          sx={{ minWidth: 275 }}
          className="locationCard"
          style={{
            position: "absolute",
            width: "90%",
            height: "auto", //"13%",
            bottom: "1%",
            left: "5%",
            zIndex: 10000,
          }}
        >
          <CustomCard
            eventId={activeEvent.id}
            food={activeEvent.foodTypeText || ""}
            location={activeEvent.venueText || ""}
            rsvp={activeEvent.rsvpLink || ""}
            time={activeEvent.startTime || ""}
            title={activeEvent.eventName || ""}
          />
        </Box>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default HomePage;
